import Router from "./Router";
// import Base from "./three/Base";
// import {createBaseElements, baseElements} from "./BaseElements";
import Dispatcher from "./Dispatcher";
// import DomElement from "./utils/DomElement";
import Header from "./Components/Header";
// import Reef from 'reefjs';
import AppRender from "./Components/AppRender";
import Footer from "./Components/Footer";
import ThreeScene from "./three/ThreeScene";
import {setIsLocal} from "./utils/isLocal"


export default class App {

    constructor() {
    
        setIsLocal()
        const appRender = new AppRender()
        const route = new Router()
        const dispatch = new Dispatcher(route, appRender)
        const footer = new Footer(appRender)
        appRender.render()
        const header = new Header(appRender)
    
        this.isMobile = window.innerWidth < 992 ? true : false
        
        const allPromises = dispatch.getPromises()
        
        if (!this.isMobile) {
            new ThreeScene({
                promises: allPromises
            })
        }
        
    }


}