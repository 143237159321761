import Home from "./pages/Home";

export default class Dispatcher {

    constructor(route, appRender) {
        
        this.appRender = appRender
        this.router = route
        this.dispatchEvent()
        this.initialDispatch()

    }

    initialDispatch() {

        this.dispatch(this.router.currentRoute)

    }

    dispatchEvent() {

        this.router.on('navigate', () => {

            this.dispatch(this.router.currentRoute)

        })

    }

    dispatch(route) {
      
        switch (route) {

            case 'home':
                this.currentPageClas = new Home(this.appRender)
                break

        }

        // pageClass.finalRender();

    }
    
    getPromises() {
        console.log(typeof this.currentPageClas.getPromises);
        if (typeof this.currentPageClas.getPromises === 'function') {
            return this.currentPageClas.getPromises()
        }
        return []
    }

}