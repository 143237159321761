import {fetchData} from "../FetchData";
import Reef from "reefjs/dist/reef.es";
import servicesData from '../localData/services.json';
import Contact from './../Components/Contact'
Reef.debug(true);
export default class Home {
    
    constructor(appRender) {
        
        this.homePromises = []
        this.appRender = appRender
        this.domElements = ``
        this.createIntro()
        this.createServicesBlock()
        this.createProjectsBlock()
        this.createForm()
        this.appendToRender()
        
        
    }
    
    createIntro() {
        
        this.domElements = `
        
            <section id="home-top">
                <div class="aa-container">
                    <div class="aa-column aa-column-lg-5 position-relative">
                        <img src="src/images/svg/m.svg" id="home-top-m" alt="Illustration M">
                    </div>
                    <div class="aa-column aa-column-lg-7">
                    <div class="home-top__content">
                        <h1 class="h1">Maxime Pascal
                        <span class="home-top__subtitle">Développeur Front-End <span>//</span> Freelance</span></h1>
                        <p class="home-top__text">
                            Développeur web depuis cinq ans spécialisé dans le Front-end, je travaille sur Wordpress, Prestashop ou toute autre solution sur mesure.
                          
                            Du site vitrine simple au site e-commerce, je prends en charge l'intégralité d'un développement adapté à vos besoins ainsi que les optimisations SEO et Google web vitals.
                        </p>
                    </div>
                    </div>
                </div>
                <div class="home-top__bg"></div>
            </section>
            
        `
        
    }
    
    projectTemplate(project) {
        return `
            <article class="project aa-column aa-column-md-6">
                <div class="project__inner">
                    <img src="${project.data.projectimage.url}" alt="Image de ${project.data.title[0].text}">
                    <h3 class="h3">${project.data.title[0].text}</h3>
                    <p>${project.data.shorttext[0].text}</p>
                    ${project.data.projectlink ? `<a class="project__link" target="_blank" href="${project.data.projectlink.url}">Voir le site</a>` : ``}
                </div>
            </article>
                `
    }
    
    serviceTemplate(service) {
        return `
            <article class="service service-${service.class} aa-column aa-column-xl-3 aa-column-md-6">
            <div class="service__inner">
                <h3 class="h3">${service.title}</h3>
                <p class="text-sm">${service.text}</p>
            </div>
            </article> `;
    }
    
    createServicesBlock() {
    
        this.domElements += `
            
            <section id="services">
                <div class="aa-container">
                    <div class="aa-column">
                        <h2 class="h2">Services</h2>
                    </div>
                    <div class="aa-container aa-container--nested">
                        ${servicesData.map(service => this.serviceTemplate(service) ).join('')}
                    </div>
                   <div class="services__bg"></div>
                </div>
            </section>
            
            `;
        
            
        
    }
    createProjectsBlock() {
        
        let serviceHTML = ''
        const options = {}
        options.at = { value: 'projets' }

        const projetPromise = fetchData(options).then((data) => {
            console.log('project Loaded');
            serviceHTML = `

            <section id="projets">
                <div class="aa-container">
                    <div class="aa-column">
                        <h2 class="h2">Projets</h2>
                    </div>
                    <div class="aa-container aa-container--nested">
                        ${data.results.map(project => this.projectTemplate(project) )}
                    </div>
                    <div class="projets__bg"></div>
                </div>
            </section>

            `;
    
            this.appRender.appendElementsInDom({
                position: 'afterend',
                html: serviceHTML,
                selector: '#services'
            })


        })
        
        this.homePromises.push(projetPromise)
        
    }
    
    createForm() {
        const contactForm = new Contact()
        this.domElements += contactForm.getDom()
        
    }
    
    
    appendToRender() {
        
        this.appRender.addElements(this.domElements)
        
    }
    
    getPromises() {
        return this.homePromises
    }
    
}


// Add element to existing dom
//         const dom = this.appRender.getCurrentDom()
//         dom.getElementById('home-top').insertAdjacentHTML('beforeend', '<div>MAIS OUAIS</div>');
//         this.appRender.addElements(dom, true, true)
//         this.appRender.render()