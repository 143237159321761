import Prismic from "prismic-javascript";
const apiEndpoint = 'https://maximepascal.cdn.prismic.io/api/v2'

const fetchData = (parameters) => {

    const _parameters = {...parameters}
    if ('undefined' === typeof _parameters.at.fragment) {
        _parameters.at.fragment = 'document.type'
    }
    if ('undefined' === typeof _parameters.options) {
        _parameters.options = { orderings : '[my.'+_parameters.at.value+'.date desc]' }
    }

    const client = Prismic.client(apiEndpoint, { accessToken: '' })
    const result = client.query(
        Prismic.Predicates.at(_parameters.at.fragment, _parameters.at.value),
        _parameters.options
    )

    return result

    //const result = client.query(
    //             Prismic.Predicates.at('document.type','events'),
    //             { orderings : '[my.events.date desc]' }
    //         )


}

export {fetchData}

