let isLocal = false

const getIsLocal = () => {
    return isLocal;
}

const setIsLocal = () => {

    const location = window.location.origin
    isLocal = location.includes('localhost')

}

export {isLocal, setIsLocal}