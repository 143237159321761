export default class Footer {
    
    constructor(appRender) {
        
        this.appRender = appRender
        this.createHeader()
        this.appendToRender()
        
    }
    
    createHeader() {
        
        this.domElements = `
        
         
            <footer id="footer">
               <div class="aa-container">
                <div class="footer__wrapper aa-column aa-column-12">
                    <a title="Linkedin" class="footer__social footer__social--linkedin" target="_blank" href="https://www.linkedin.com/in/maxime-pascal-a4a20bba/" rel="nofollow noreferrer noopener">
                    
                    </a>
                    <a title="Malt" class="footer__social footer__social--malt" target="_blank" href="https://www.malt.fr/profile/maximepascal" rel="nofollow noreferrer noopener">
                    
                    </a>
                </div>
            </div>
            
            </footer>
       
        `
        
    }
    
    appendToRender() {
    
        this.appRender.addElements(this.domElements)
        
    }
    
}