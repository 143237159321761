export default class Router {

    constructor() {

        this.fakeDomElement = document.createElement('div')
        this.events = {}

        this.routes = {
            '/new/index.html' : 'home',
            '/' : 'home',
            // '/evenements' : 'events',
        }

        this.routeState = 'active'
        this.routeTransitionTiming = 0

        this.currentPath = this.getCurrentPath()
        this.currentRoute = this.routes[this.currentPath]
        this.previousPath = false
    
    
        console.log(this.currentRoute);
    
        this.events = {
            onPopState : new CustomEvent("popState"),
            onNavigate : new CustomEvent("navigate", {
                detail: {
                    currentRoute: this.currentRoute,
                    currentPath: this.currentPath,
                    previousPath: this.previousPath,
                }
            }),
        }


        this.init()

        // this.root.innerHTML = this.routes[window.location.pathname];

    }

    init = () => {
        this.handleLinks()
        this.onPopState()
    }

    handleLinks = () =>  {

        document.addEventListener('click', (e) => {

            // const elementClicked = e.target

            // if (!elementClicked.classList.contains('outer-link')) {
            //     e.preventDefault()
            //     const href = elementClicked.getAttribute('href')
            //     this.onNavigate(href)
            // }


        })

    }

    /*
     * Event methode
     * Event name - Function
     */
    on = (eventToCall, callback) => {

        // Create only one eventListener for each type
        // Check if events to listen is already active
        if (this.events[eventToCall] === undefined) {

            // If not : create it
            this.events[eventToCall] = [callback]

            this.fakeDomElement.addEventListener(eventToCall, () => {

                this.events[eventToCall].forEach(arrayCallback => {
                    if (typeof arrayCallback === 'function') {
                        arrayCallback()
                    } else {
                        console.error('Argument "eventToCall" must be a function : ' + arrayCallback)
                    }
                })

            })

        // If it exists, push the function to the array
        } else {
            this.events[eventToCall].push(callback)
        }

    }

    getCurrentPath = () => {
        return window.location.pathname
    }

    updateRouteState = () => {

        this.routeState = 'in-transition'

        setTimeout(() => {
          this.routeState = 'active'
        },this.routeTransitionTiming)

    }

    onPopState = () => {

        window.onpopstate = () => {
            this.updateCurrentPath(this.getCurrentPath())
            this.updateRouteState()
            this.fakeDomElement.dispatchEvent(this.events.onPopState);
        }

    }

    updateCurrentPath = (path) => {
        this.previousPath = this.currentPath
        this.currentPath = path
        this.currentRoute = this.routes[this.currentPath]
    }


    onNavigate = (pathname) => {

        window.history.pushState(
            {},
            pathname,
            window.location.origin + pathname
        )
        this.updateCurrentPath(pathname)
        this.updateRouteState()
        this.fakeDomElement.dispatchEvent(this.events.onNavigate);
        // this.root.innerHTML = this.routes[pathname]
    }

}