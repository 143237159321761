
import * as THREE from 'three'
import * as dat from 'dat.gui'
import gsap from 'gsap'
import Scroll from "./../utils/scroll";
import imagesLoaded from 'imagesloaded';
import fragment from './shaders/fragment.glsl'
import vertex from './shaders/vertex.glsl'
import Stats from "stats.js"
import {isLocal} from "../utils/isLocal";


export default class ThreeScene {
    
    constructor(options) {
        this.externalPromises = options.promises
        this.init();
    }
    
    init() {
    
        this.scroll = null
        this.currentScroll = 0;
        this.canvas = document.createElement('canvas')
        this.canvas.classList.add('webgl')
        document.body.append(this.canvas)
        
        if (isLocal) {
            this.gui = new dat.GUI()
        }
        this.scene = new THREE.Scene()
        this.clock = new THREE.Clock()
        this.oldElapsedTime = 0
        this.sizes = {
            width: window.innerWidth,
            height: window.innerHeight
        }
        this.parameters = {
        
        }
        
        this.createCamera()
        
        
        document.addEventListener('reef:render', () => {
            
            const preloadImages = new Promise((resolve, reject) => {
                imagesLoaded(document.querySelectorAll("img"), { background: true }, resolve);
            });
            
            Promise.all([preloadImages, ...this.externalPromises]).then(()=>{
                
                this.getDomForWebgl()
                this.scroll = new Scroll()
                this.createThreeElements()
                this.addElementsToScene()
            })
            
        })

        
        this.raycaster()
        this.handleMouse()
        this.handleResize()
        if (isLocal) {
            this.statsJs()
        }
        this.setFixedElements()
        this.createRenderer()
        this.tick()
        
        
        
    }
    
    setFixedElements() {
        
        this.fixedElements = document.getElementsByClassName('is-fixed');
        
    }
    
    statsJs() {
        this.stats = new Stats()
        this.stats.showPanel(0) // 0: fps, 1: ms, 2: mb, 3+: custom
        document.body.appendChild(this.stats.dom)
    }
    
    
    getDomForWebgl() {
    
    
       this.domForWebgl = [
           {
               selector: document.getElementsByClassName('home-top__bg')[0],
               type: 'plainColor',
               color: '#111F30',
               multiplier: 35.0,
               uSinMultiplier: 0.003,
               widthSegment: 50,
               heightSegment: 20,
               position: {
                   z: 0.0
               }
           },
           {
               selector: document.getElementsByClassName('home-top__bg')[0],
               type: 'plainColor',
               color: '#21CCC8',
               multiplier: 45.0,
               uSinMultiplier: 0.003,
               widthSegment: 50,
               heightSegment: 20,
               position: {
                   z: -0.1
               }
           },
           {
               selector: document.getElementsByClassName('home-top__bg')[0],
               type: 'plainColor',
               color: '#FD6363',
               multiplier: 55.0,
               uSinMultiplier: 0.003,
               widthSegment: 50,
               heightSegment: 20,
               position: {
                   z: -0.2
               }
           },
           {
               selector: document.getElementsByClassName('projets__bg')[0],
               type: 'plainColor',
               color: '#111F30',
               multiplier: 35.0,
               uSinMultiplier: - 0.005,
               widthSegment: 50,
               heightSegment: 20,
               position: {
                   z: -0.0
               }
           },
           {
               selector: document.getElementsByClassName('projets__bg')[0],
               type: 'plainColor',
               color: '#21CCC8',
               multiplier: 45.0,
               uSinMultiplier: - 0.005,
               widthSegment: 50,
               heightSegment: 20,
               position: {
                   z: -0.1
               }
           },
           {
               selector: document.getElementsByClassName('projets__bg')[0],
               type: 'plainColor',
               color: '#FD6363',
               multiplier: 55.0,
               uSinMultiplier: - 0.005,
               widthSegment: 50,
               heightSegment: 20,
               position: {
                   z: -0.2
               }
           },
        ]
        
    }
    
    createThreeElements() {
        this.material = new THREE.ShaderMaterial({
            vertexShader: vertex,
            fragmentShader: fragment,
            wireframe: false,
            uniforms: {
                uScrollSpeed: null,
                uSpeedMultiplier: 20.0,
                uSinMultiplier: 0.003,
                uTime: {
                    value: 0
                },
                uColor: {
                    value: new THREE.Color('black'),
                },
            }
        })
    
        this.webglElements = []
    
        this.domForWebgl.forEach(element => {
            console.log(element.selector);
            if (!element.selector) {
                return false
            }
            const bound = element.selector.getBoundingClientRect()
            const material = this.material.clone()
            material.uniforms.uColor.value = new THREE.Color(element.color)
            material.uniforms.uSpeedMultiplier.value = element.multiplier
            material.uniforms.uSinMultiplier.value = element.uSinMultiplier
    
            const mesh = new THREE.Mesh(
                new THREE.PlaneBufferGeometry( bound.width, bound.height, element.widthSegment, element.heightSegment),
                material
            )
            mesh.position.z = element.position.z
            
            this.webglElements.push({
                selector: element.selector,
                bound,
                mesh,
            })
    
            
        })
        
    }
    
    addElementsToScene() {
        
        this.webglElements.forEach(element => {
            
            this.scene.add(element.mesh)
            element.selector.classList.add('webgl-loaded')
            
        })
        
    }
    
    updateElementsPositions() {
        
        this.webglElements.forEach(element => {
            
            element.mesh.position.y = this.currentScroll - element.bound.top + this.sizes.height / 2 - element.bound.height / 2;
            element.mesh.position.x = element.bound.left - this.sizes.width / 2 + element.bound.width / 2;
        
        })
    }
    
    
    raycaster() {
        
        this.raycaster = new THREE.Raycaster()
        this.currentIntersect = null
        this.rayOrigin = new THREE.Vector3(- 3, 0, 0)
        this.rayDirection = new THREE.Vector3(10, 0, 0)
        this.rayDirection.normalize()
        
    }
    
    handleMouse() {
        
        this.mouse = new THREE.Vector2()
        
        window.addEventListener('mousemove', (event) =>
        {
            this.mouse.x = event.clientX / this.sizes.width * 2 - 1
            this.mouse.y = - (event.clientY / this.sizes.height) * 2 + 1
        })
        
        
    }
    
    
    handleResize() {
        window.addEventListener('resize', () =>
        {
            // Update sizes
            this.sizes.width = window.innerWidth
            this.sizes.height = window.innerHeight
            
            // Update camera
            this.camera.aspect = this.sizes.width / this.sizes.height
            this.camera.updateProjectionMatrix()
            
            // Update renderer
            this.renderer.setSize(this.sizes.width, this.sizes.height)
            this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
            this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
        })
    }
    
    createCamera() {
        
        this.cameraPerspective = 800
        const fov = (180 * (2 * Math.atan(this.sizes.height / 2 / this.cameraPerspective))) / Math.PI
        this.camera = new THREE.PerspectiveCamera(fov, this.sizes.width / this.sizes.height, 1, 1000)
        this.camera.position.set(0, 0, this.cameraPerspective)
        this.scene.add(this.camera)
    }
    
    
    createRenderer() {
        this.renderer = new THREE.WebGLRenderer({
            canvas: this.canvas,
            alpha: true,
            antialias: true,
        })
        this.renderer.shadowMap.enabled = true
        this.renderer.shadowMap.type = THREE.PCFSoftShadowMap
        this.renderer.setSize(this.sizes.width, this.sizes.height)
        this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    }
    
    tick = () => {
    
        const elapsedTime = this.clock.getElapsedTime()
        const deltaTime = elapsedTime - this.oldElapsedTime
        this.oldElapsedTime = elapsedTime
        
        if (this.stats) {
            this.stats.begin()
        }
        
        if (this.scroll) {
            this.scroll.render()
            this.currentScroll = this.scroll.scrollToRender
            this.updateElementsPositions()
            this.webglElements.forEach(element => {
                element.mesh.material.uniforms.uScrollSpeed.value = this.scroll.speedTarget;
                element.mesh.material.uniforms.uTime.value = elapsedTime * 2;
            })
            this.scrollSpeed = this.scroll.speedTarget;
        }
        
        
        
        
        // Render
        this.renderer.render(this.scene, this.camera)
        
        // Call tick again on the next frame
        window.requestAnimationFrame(this.tick)
        
        if (this.stats) {
            this.stats.end()
        }
    }
    
}
