export default class Contact  {
    
    constructor() {
        
        this.eventsBinded = false
        this.createDom()
        this.bindEvents()
    }
    
    createDom() {
        this.dom = `
            <section id="contact">
            
            <div class="aa-container">
                <div class="aa-column aa-column-lg-6">
                 <h2 class="h2">Contact</h2>
                       <p>Un projet, une idée ? N'hésitez pas à me contacter par mail à maxime0pascal[at]gmail.com ou via le formulaire.</p>
                </div>
                <div class="aa-column aa-column-lg-6">
                    <form class="aa-container aa-container--nested" id="form-contact" method="post">
                        <div class="form-column aa-column aa-column-md-6">
                            <label for="last-name">Prénom *</label>
                            <input placeholder="Entrez votre prénom" class="input-text" type="text" name="name" required>
                        </div>
                        <div class="form-column aa-column aa-column-md-6">
                            <label for="name">Nom *</label>
                            <input placeholder="Entrez votre nom" class="input-text" type="text" name="last-name" required>
                        </div>
                        <div class="form-column aa-column aa-column-md-6">
                            <label for="email">E-mail *</label>
                            <input placeholder="Entrez votre E-mail" name="email" type="email" required>
                        </div>
                        <div class="form-column aa-column aa-column-md-6">
                            <label for="phone">Téléphone</label>
                            <input placeholder="Entrez votre téléphone" name="phone" type="phone">
                        </div>
                        <div class="form-column aa-column aa-column-md-12">
                            <label for="text">Message *</label>
                            <textarea placeholder="Entrez votre message" name="text" required></textarea>
                        </div>
                        <div class="form-column aa-column aa-column-md-4">
                            <button class="btn btn--primary" type="submit">Envoyer</button>
                        </div>
                        <div class="form-column aa-column aa-column-md-8 form-contact__message">
                        </div>
                    </form>
                </div>
            </div>
           
            
           </section>
        `
    }
    
    bindEvents() {
        
        
        document.addEventListener('reef:render', () => {
            
            if (!this.eventsBinded) {
                
                this.eventsBinded = true
                const formContact = document.getElementById('form-contact')
                
                if (formContact) {
                    
                    const formSubmit = (e) => {
                        e.preventDefault()
                        const formData = new FormData();
                        const inputsData = [
                            {
                                name: 'name',
                                value: document.querySelector('#form-contact input[name="name"]') ? document.querySelector('#form-contact input[name="name"]').value : ''
                            },
                            {
                                name: 'last-name',
                                value: document.querySelector('#form-contact input[name="last-name"]') ? document.querySelector('#form-contact input[name="last-name"]').value : ''
                            },
                            {
                                name: 'email',
                                value: document.querySelector('#form-contact input[name="email"]') ? document.querySelector('#form-contact input[name="email"]').value : ''
                            },
                            {
                                name: 'phone',
                                value: document.querySelector('#form-contact input[name="phone"]') ? document.querySelector('#form-contact input[name="phone"]').value : ''
                            },
                            {
                                name: 'text',
                                value: document.querySelector('#form-contact textarea[name="text"]') ? document.querySelector('#form-contact textarea[name="text"]').value : ''
                            },
                        ]
                        
                        inputsData.forEach((input) => {
                            formData.append(
                                input.name,
                                input.value,
                            )
                        })
             
                        fetch(document.location.origin + '/contact-form.php',
                            {
                                method: "POST",
                                body: formData,
                            })
                            .then(response => {
                                
                                if (response.status === 200) {
                                    this.clearInputs()
                    
                                    this.showMessage('success','Votre mail a bien été envoyé')
                                } else {
                                    this.showMessage('error','Un problème a été rencontré lors de l\'envoi du message, merci de réessayer ou d\'envoyer un mail à l\'adresse suivante : maxime0pascal@gmail.com')
                                }
                                
                            })
                            .catch(error => console.log(error))
                        
                    }
                    
                    formContact.addEventListener("submit", formSubmit);
                    
                }
                
            }
        });
        
        
    }
    
    clearInputs() {
        
        document.querySelectorAll('#form-contact input, #form-contact textarea').forEach(input => {
            input.value = ''
        })
        
    }
    
    showMessage(status, message) {
        
        const messagePlaceholder = document.getElementsByClassName('form-contact__message')[0]
        if (messagePlaceholder) {
            
            messagePlaceholder.innerHTML = '<p class="'+status+'"> ' + message + '</p>'
            
        }
        
    }
    
    getDom() {
        return this.dom
    }
    
}
