export default class Header {
    
    constructor(appRender) {
        
        this.appRender = appRender
        this.enventBinded = false
        this.createHeader()
        this.bindEvents()
        this.appendToRender()
        
    }
    
    createHeader() {
        
        const links = `
            <a class="main-nav__link" href="#services" data-scroll-offset="-200">Services</a>
            <a class="main-nav__link" href="#projets" data-scroll-offset="${window.innerWidth < 992 ? 300 : 240}">Projets</a>
            <a class="main-nav__link" href="#contact">Contact</a>
        `
        
        this.mobileMenu = `
            
            <header id="header">
                <button type="button" class="burger-btn"></button>
            </header>
            <div class="main-nav main-nav--mobile">
                <div class="main-nav--mobile__background main-nav--mobile__background--1"></div>
                <div class="main-nav--mobile__background main-nav--mobile__background--2"></div>
                <div class="main-nav--mobile__background main-nav--mobile__background--3"></div>
                ${links}
                <button class="main-nav__close" type="button"></button>
            </div>
        
        `
        
        this.desktopMenu = `
        
            <header id="header" class="is-fixed">
                <div class="aa-container">
                    <div class="aa-column aa-column-12">
                        <nav class="main-nav">
                            <a class="main-nav__logo main-nav__link" href="/"></a>
                            ${links}
                        </nav>
                    </div>
                </div>
            </header>
       
        `
    
        this.domElements = window.innerWidth < 992 ? this.mobileMenu : this.desktopMenu
        
    }
    
    appendToRender() {
        
        this.appRender.insertOutsideOfApp({
            position: 'beforebegin',
            html: this.domElements,
        })
        
    }
    
    bindEvents() {
        
        document.addEventListener('reef:render', function (event) {
        
            const header = document.getElementById('header')
            const mobileMenu = document.getElementsByClassName('main-nav--mobile')
        
            if (header && !this.enventBinded) {
                this.enventBinded = true
                const navLinks = document.getElementsByClassName('main-nav__link');
                
                navLinks.forEach((link) => {
                    
                    link.addEventListener('click', (e) => {
                        
                        e.preventDefault()
                        let finalScroll
                        
                        if (link.getAttribute('href') === '/') {
                            
                            finalScroll = 0
                            
                        } else {
                            const anchor = link.getAttribute('href').replace('#', '')
                            let elementToScrollPosition = document.getElementById(anchor).offsetTop
                            const offset = link.getAttribute('data-scroll-offset') ? Number(link.getAttribute('data-scroll-offset')) : 0
                            finalScroll = elementToScrollPosition + offset
                        }
                        
                        
                        window.scroll({
                            top: finalScroll,
                            left: 0,
                            behavior: 'smooth'
                        })
                    })
                    
                })
            }
            
            if (mobileMenu[0]) {
                
                
                document.getElementsByClassName('burger-btn')[0].addEventListener('click', () => {
                    mobileMenu[0].classList.add('is-active')
                })
    
                document.getElementsByClassName('main-nav__close')[0].addEventListener('click',() => {;
                    mobileMenu[0].classList.remove('is-active')
                })
    
                document.querySelectorAll('.main-nav__link').forEach((link) => {
                    link.addEventListener('click',() => {
                        mobileMenu[0].classList.remove('is-active')
                    })
                })
                
            }
            
        });
        
    }
    
}