import Reef from "reefjs/dist/reef.es";
Reef.debug(true)

const stringToDom = (string) => {
    
    let parser = new DOMParser()
    const dom = parser.parseFromString(string, "text/html")
    return dom
    
}


/*
 * Manage main render of page
 */
export default class appRender {
    
    constructor() {
        
        // Store all dom elements
        this.domElements = ``;
        this.mainReef = new Reef('#app', {
            template: () => {
                return this.domElements
            }
        });
        
    }
    
    insertOutsideOfApp(param) {
    
        document.getElementById('app').insertAdjacentHTML(param.position, param.html)
    
    }
    
    appendElementsInDom(param) {
        
        const dom = this.getCurrentDom()
        dom.querySelector(param.selector).insertAdjacentHTML(param.position, param.html);
        this.addElements(dom, true, true)
        this.render()
        console.log('render');
    
    }
    
    // Get dom as a html element
    getCurrentDom() {
        return stringToDom(this.domElements)
    }
    
    // Set dom as string
    setAsString(dom) {
        return new String(dom)
    }
    
    // Add elements in the render, if is dom convert it as string, replace or not all content
    addElements(template, isDom = false, replace = false) {
        
        if (isDom) {
            template = this.setAsString(template.body.innerHTML)
        }
        
        if (replace) {
            this.domElements = template
        } else {
            this.domElements += template
        }
        
    }
    
    render() {
        
        this.mainReef.render()
        
    }
    
}